import React from 'react';

import type { Editor } from 'components/TrixEditor/TrixEditor';

import BaseTextarea, {
  type Props as BaseProps,
} from '../bulmaElements/Textarea';
import RichTextEditor from './RichTextEditor';
import WithCharacterCountWrapper from './WithCharacterCountWrapper';

type Props = BaseProps & {
  richText?: false;
  mergeTags?: Array<{
    trigger: string;
    tags: Array<{ name: string; tag: string }>;
  }>;
  onSaveButtonClick?: () => void;
  hideSaveButton?: boolean;
};

type RichTextProps = Omit<Props, 'onChange' | 'richText'> & {
  richText: true;
  onChange?: (html: string, text: string) => void;
  richTextOptions?: {
    onEditorReady?: (editor: Editor) => void;
    toolbarAlwaysVisible?: boolean;
    allowImageUpload?: boolean;
    fixedTagSelector?: boolean;
  };
};

export default function TextArea({
  mergeTags,
  onSaveButtonClick,
  hideSaveButton,
  value,
  disabled,
  placeholder,
  autoFocus,
  onBlur,
  onEscapeKeyPress,
  style,
  additionalClassName,
  maxLength,
  onMaxLengthReached,
  ...otherProps
}: Props | RichTextProps) {
  const textAreaRenderer = (maxLengthReached = false) => {
    const state = otherProps.state || (maxLengthReached ? 'danger' : undefined);

    return otherProps.richText ? (
      <RichTextEditor
        {...otherProps.richTextOptions}
        value={value || ''}
        onChange={otherProps.onChange}
        disabled={disabled}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onBlur={onBlur}
        onEscapeKeyPress={onEscapeKeyPress}
        style={style}
        additionalClassName={additionalClassName}
        onSaveButtonClick={onSaveButtonClick}
        mergeTags={mergeTags}
      />
    ) : (
      <BaseTextarea
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        onBlur={onBlur}
        onEscapeKeyPress={onEscapeKeyPress}
        style={style}
        additionalClassName={additionalClassName}
        maxLength={maxLength}
        onMaxLengthReached={onMaxLengthReached}
        state={state}
        {...otherProps}
      />
    );
  };

  if (!!maxLength) {
    return (
      <WithCharacterCountWrapper
        render={textAreaRenderer}
        count={value?.length || 0}
        maxCount={maxLength}
        onMaxCountReached={onMaxLengthReached}
      />
    );
  }

  return textAreaRenderer();
}
