import React from 'react';

import type { HomeMessage, NavActionsCount, Organization, User } from 'models';
import type { ComponentType } from 'react';

import { useActiveUser, useAppSelector, useOrganization } from 'helpers/hooks';

import { hydrateFromStore } from 'lib/dataLoader';
import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { DesignSystem } from 'components';

import HomeBanner from './HomeBanner';
import HomeLogo from './HomeLogo';
import HomeMainGrid from './components/HomeMainGrid';

type Props = {};

type AfterConnectProps = {
  organization: Organization;
  activeUser: User;
  navActionsCount: NavActionsCount;
  homeMessage: HomeMessage;
} & Props &
  DataLoaderProvidedProps;

const Homepage = ({
  homeMessage,
  isFetching,
  hasError,
  refetchData,
}: AfterConnectProps) => {
  const organization = useOrganization();
  const activeUser = useActiveUser();

  const navActionsCount = useAppSelector(state =>
    hydrateFromStore(
      state.data,
      {
        resourceType: 'navActionsCount',
        id: 'nav_actions_count',
      },
      { navActionsCount: {} }
    )
  );

  return (
    <DesignSystem version={2}>
      <div className="homepage mt-14 md:mt-0">
        <HomeBanner />
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <HomeLogo />
        </div>
        <div className="mx-auto max-w-7xl mt-8 px-4 sm:px-6 lg:px-8">
          <HomeMainGrid
            organization={organization}
            user={activeUser}
            actionsCount={navActionsCount}
            homeMessage={homeMessage}
            isFetchingHomeMessage={isFetching}
            hasError={hasError}
            refetchHomeMessage={refetchData}
          />
        </div>
      </div>
    </DesignSystem>
  );
};

export default newDataLoader({
  fetch: () => get('/home_messages/current'),
  hydrate: {
    homeMessage: {},
  },
})(Homepage) as ComponentType<Props>;
